import React from "react"
import DocumentationTemplate from "./documentationTemplate";

import {connect} from 'react-redux';
import { setSettings, setPermissions, setServices, setAccountingMapping, setState } from '../reduxStores/reducers/Main';
import Cookies from "js-cookie";
import {graphql} from "gatsby";

import { config } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTextSize, faXmark, faMemo } from '@fortawesome/pro-regular-svg-icons'
import { faBooks, faBug, faPencil, faFileCircleExclamation } from '@fortawesome/pro-solid-svg-icons'

import html2canvas from 'html2canvas';
import * as markerjs2 from 'markerjs2';
import queryString from "query-string";
import Modal from 'react-modal';

import IntercomFin from "./intercom-fin";
import SearchInterface from "./searchInterace";
import fetch from 'cross-fetch';

config.autoAddCss = false

class indexLayout extends React.Component {
  constructor(props) {
    super();
    this.retrieveSettings = this.retrieveSettings.bind(this);
    this.retrieveSettings(props).then(() => {
      if (typeof window === 'undefined' || !this.props.Main.settings) return;
      window.Intercom("boot", {
        app_id: process.env.GATSBY_INTERCOM_APP_ID,
        user_id: this.props.Main.settings.user.id,
        user_hash: this.props.Main.settings.intercom_hash,
        alignment: 'right',
        hide_default_launcher: false,
      });

      window.FS.identify(this.props.Main.settings.user.id, {
        displayName: this.props.Main.settings.user.name,
        email: this.props.Main.settings.user.email,
        'firstName_str': this.props.Main.settings.user.name_first,
        'lastName_str': this.props.Main.settings.user.name_last,
        'username_str': this.props.Main.settings.user.username,
        'company.name_str': this.props.Main.settings.dealership_info.name,
        'company.id_int': this.props.Main.settings.dealership_info.id,
      });

      setTimeout(() => {
        if (this.props.Main.settings.admin) {
          const feedbackButton = document.getElementById("feedback-button");
          if (feedbackButton) {
            feedbackButton.className += " feedback-button--shown";
          }
        }
      }, 500)
    });


    this.state = {
      showFeedbackModal: false,
      feedbackForm: {
        message: ""
      }
    };

    this.sendFeedback = this.sendFeedback.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.startFeedback = this.startFeedback.bind(this);
    this.handleFeedbackMessageChange = this.handleFeedbackMessageChange.bind(this);
    this.captureScreenshot = this.captureScreenshot.bind(this);
    this.editScreenshot = this.editScreenshot.bind(this);
    this.removeScreenshot = this.removeScreenshot.bind(this);
    this.submitFeedback = this.submitFeedback.bind(this);
  }

  componentDidMount() {
    const interval = setInterval(() => {
      if (typeof document === 'undefined' || !window.location.hash) return;
      const item = document.querySelector(`${window.location.hash}`);
      if (item) {
        clearInterval(interval);
        clearInterval(timeout);
        const footer = document.querySelector(`.main-footer`);

        let distance = item.offsetTop;
        if (footer.offsetTop - item.offsetTop < window.innerHeight) {
          distance = footer.offsetTop - window.innerHeight;
        }

        window.scrollTo({
          top: distance,
          behavior: "instant",
        });

        item.classList.add("highlight-anchor-tag");

        setTimeout(() => {
          item.classList.remove("highlight-anchor-tag");
        }, 2000)
      }
    }, 100)
    const timeout = setTimeout(() => {
      clearInterval(interval);
    }, 1000);
  }

  shouldComponentUpdate(nextProps, nextState) {
    // If we're only changing the hash, don't re-render the component.
    if (nextProps.location.pathname === this.props.location.pathname && nextProps.location.hash !== this.props.location.hash) {
      return false;
    }

    return true;
  } 
  
  sendFeedback() {
    this.setState({ showFeedbackModal: true, feedbackForm: { message: '' }, feedbackType: null });
  }

  captureScreenshot() {
    this.setState({
      capturingScreenshot: true,
      feedbackForm: {
        ...this.state.feedbackForm,
        screenshot: null,
      }
    });

    const currentScrollY = window.scrollY;
    const gatsbyElement = document.getElementById("___gatsby");
    if (gatsbyElement.className !== 'screenshot-prepare') {
      gatsbyElement.className = 'screenshot-prepare';

      gatsbyElement.scrollTo({
        top: currentScrollY,
        behavior: "instant",
      });
    }
    setTimeout(() => {
      html2canvas(gatsbyElement, {
        height: window.innerHeight,
        ignoreElements: (element) => {
          return element.className === 'ReactModalPortal';
        },
        proxy: "https://manage.dealr.cloud/api/public/helpCenter/imageProxy"
      }).then((canvas) => {
        //gatsbyElement.className = '';
        /*window.scrollTo({
          top: currentScrollY,
          behavior: "instant",
        });*/
        this.setState({
          capturingScreenshot: false,
        });
  
        setTimeout(() => {
          const imgUrl = canvas.toDataURL('image/png');
  
          const currentElement = document.getElementById('screenshot-image');
          if (currentElement) {
            currentElement.remove();
          }
    
          var img = document.createElement("img");
          img.src = imgUrl;
          img.id = "screenshot-image";
          document.body.appendChild(img);
    
          const target = document.getElementById('screenshot-image');
    
          const markerArea = new markerjs2.MarkerArea(target);
          // open the editor in a popup
          markerArea.settings.displayMode = 'popup';
          markerArea.addEventListener("render", (event) => {
            this.setState({
              feedbackForm: {
                ...this.state.feedbackForm,
                screenshot: event.dataUrl,
              }
            });
          });
          markerArea.show();
        });        
      });
    }, 100);
  }

  editScreenshot() {
    const target = document.getElementById('feedback-form__screenshot');

    const markerArea = new markerjs2.MarkerArea(target);
    // open the editor in a popup
    markerArea.settings.displayMode = 'popup';
    markerArea.addEventListener("render", (event) => {
      this.setState({
        feedbackForm: {
          ...this.state.feedbackForm,
          screenshot: event.dataUrl,
        }
      });
    });
    markerArea.show();
  }

  removeScreenshot() {
    this.setState({
      feedbackForm: {
        ...this.state.feedbackForm,
        screenshot: null,
      }
    });
  }
  
  startFeedback(type) {
    this.setState({ feedbackType: type });

    switch(type) {
      case "incorrect_info":
      case "typo":
        this.captureScreenshot();
        break;
      case "documentation_request":
      case "feedback":
      case "bug_report":
        break;
      default:
        break;
    }

  }

  handleCloseModal () {
    this.setState({ showFeedbackModal: false });
  }

  handleFeedbackMessageChange(event) {
    this.setState({
      feedbackForm: {
        ...this.state.feedbackForm,
        message: event.target.value
      }
    });
  }

  async submitFeedback() {
    this.setState({ submittingFeedback: true });

    try {
      let cookie = Cookies.get('auth');
      if (cookie) {
        cookie = JSON.parse(cookie);
      }

      await fetch(`${/*'https://manage.dealr.cloud' || */process.env.GATSBY_API_URL}/api/admin/helpCenterFeedback`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
          type: this.state.feedbackType,
          message: this.state.feedbackForm.message,
          screenshot: this.state.feedbackForm.screenshot,
          url: window ? window.location.href : null,
        }),
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': cookie.token
        },
      });

      this.handleCloseModal();
    } catch(e) {
      alert('Feedback was not submitted.')
      console.error(e);
    }

    this.setState({ submittingFeedback: false });
  }

  async retrieveSettings(props) {
    if (props.location.hostname === 'help-fin.dealr.cloud') {
      await new Promise(res => setTimeout(res, 100));
      this.props.setState({
        settings: {
          admin: false,
          user: {
            id: 1,
            name: 'Forrest Middleton',
            name_first: 'Forrest',
            name_last: 'Middleton',
            username: 'forrestmiddleton',
            email: 'test@dealr.cloud',
          }, 
          intercom_hash: 'ad292dedd5e7b52d31e9d371dea3fbca4fac9219de069a0f2e29a6f95a0b8c49', 
          dealership_info: {
            name: 'test',
            id: '1',
            adf_email_lead: 'dealer-knight@leads.dealrcloud.com',
            leads_phone_number: '+19701234567'
          },
          state: 'CO',
        },
        permissions: {
          inventory: {
            update: true,
            view_expenses: true,
            edit_expenses: true,
          },
          deals: {
            view: true,
            update: true,
            commissions: true,
          },
          service: {
            view: true,
          },
          loans: {
            view: true,
          },
          valuations: {
            view: true,
          },
          leads: {
            view: true,
          },
          accounting: {
            view: true,
          },
        },
        services: {
          valuations: true,
          leads: true,
          service: true,
          accounting: true,
          deals: true,
          inventory: true,
          loans: true,
        },
        accountingMapping: {},
      });
      return;
    }


    if (process.env.GATSBY_DEV === '1' || process.env.NODE_ENV === 'development') {
      await new Promise(res => setTimeout(res, 100));
      this.props.setState({
        settings: {
          admin: true,
          user: {
            id: 1,
            name: 'Forrest Middleton',
            name_first: 'Forrest',
            name_last: 'Middleton',
            username: 'forrestmiddleton',
            email: 'test@dealr.cloud',
          }, 
          intercom_hash: 'ad292dedd5e7b52d31e9d371dea3fbca4fac9219de069a0f2e29a6f95a0b8c49', 
          dealership_info: {
            name: 'test',
            id: '1',
            adf_email_lead: 'dealer-knight@leads.dealrcloud.com',
            leads_phone_number: '+19701234567'
          },
          state: 'CO',
        },
        permissions: {super_admin: true},
        //permissions: {inventory: {update: true}},
        services: {
          deals: true,
          inventory: true,
          advanced_accounting_schedules: true,
        },
        accountingMapping: {},
      });
      return;
    }

    let cookie = Cookies.get('auth');
    if (cookie) {
      cookie = JSON.parse(cookie);
    } else {
      if(typeof window !== 'undefined') window.location.replace(process.env.GATSBY_API_URL+"#/access/login?forDocumentation=1&redirUrl=" + encodeURIComponent(window.location.href));
      return;
    }

    try {
      let settingsResponse = await fetch(`${process.env.GATSBY_API_URL}/api/settings/usable/`, {
        credentials: 'include',
      });
      settingsResponse = {
        data: await settingsResponse.json(),
      }
      const permissionsResponse = await fetch(`${process.env.GATSBY_API_URL}/api/userPermissions`, {
        credentials: 'include',
      });
      const mappingResponse = await fetch(`${process.env.GATSBY_API_URL}/api/settings/accountingMapping`, {
        credentials: 'include',
      });
      this.props.setState({
        settings: settingsResponse.data,
        services: settingsResponse.data.services,
        permissions: await permissionsResponse.json(),
        accountingMapping: await mappingResponse.json(),
      });
    } catch(e) {
      console.error(e);
    }
  }

  render() {
    return (<div>
      <div className={'feedback-button'} id="feedback-button" data-html2canvas-ignore={true} onClick={this.sendFeedback}>Feedback</div>

      <Modal 
           isOpen={this.state.showFeedbackModal}
           contentLabel="onRequestClose Example"
           onRequestClose={this.handleCloseModal}
           className="feedback-modal"
           overlayClassName="feedback-overlay"
        >
          {this.state.feedbackType ? 
            <div class="feedback-form">
              <div class="feedback-form__title">
                {
                  (() => {
                    switch(this.state.feedbackType) {
                      case "incorrect_info":
                        return (<div><FontAwesomeIcon className={'mr-3'} icon={faFileCircleExclamation} fixedWidth={true} /> Incorrect Information</div>);
                      case "typo":
                        return (<div><FontAwesomeIcon className={'mr-3'} icon={faTextSize} fixedWidth={true} /> Grammatical Mistake</div>);
                      case "documentation_request":
                        return (<div><FontAwesomeIcon className={'mr-3'} icon={faBooks} fixedWidth={true} /> Documentation Request</div>);
                      case "feedback":
                        return (<div><FontAwesomeIcon className={'mr-3'} icon={faMemo} fixedWidth={true} />  General Feedback</div>);
                      case "bug_report":
                        return (<div><FontAwesomeIcon className={'mr-3'} icon={faBug} fixedWidth={true} /> Bug Report</div>);
                    }
                  })()
                }
              </div>
              <div class="mb-3">
                <label for="describeIssueInput" class="form-label">Message</label>
                <textarea class="form-control" id="describeIssueInput" rows="3" value={this.state.feedbackForm.message} onChange={this.handleFeedbackMessageChange}></textarea>
              </div>
              
              <div class="mb-3">
                <label class="form-label">Screenshot 
                {this.state.feedbackForm.screenshot ? 
                <span>
                  <a onClick={this.editScreenshot} href="#"><FontAwesomeIcon className={'ml-2'} icon={faPencil} /></a>
                  <a onClick={this.removeScreenshot} href="#"><FontAwesomeIcon className={'ml-2'} icon={faXmark} /></a>
                  </span> : ""}</label>
                {
                  this.state.feedbackForm.screenshot ? 
                  <div><img src={this.state.feedbackForm.screenshot} className={'feedback-form__screenshot'} id={'feedback-form__screenshot'} /></div>
                  : 
                  <div><button className={'btn btn-light'} onClick={this.captureScreenshot} disabled={!!this.state.capturingScreenshot}>
                    {this.state.capturingScreenshot ? <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "Add Screenshot"}
                  </button></div>
                }
              </div>
              <div className="d-grid mt-4">
                <button className={'btn btn-primary btn-block'} onClick={this.submitFeedback} disabled={!!this.state.submittingFeedback}>
                  {this.state.submittingFeedback ? <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "Submit"}
                </button>
              </div>
              
            </div>
          : 
          <div class="d-flex justify-content-center">
            <div>
              <a 
              className={'feedback-type-button'} 
              onClick={() => {this.startFeedback('incorrect_info')}}>
                <FontAwesomeIcon className={'mr-3'} icon={faFileCircleExclamation} fixedWidth={true} /> Incorrect Information
              </a>

              <a 
              className={'feedback-type-button'} 
              onClick={() => {this.startFeedback('typo')}}>
                <FontAwesomeIcon className={'mr-3'} icon={faTextSize} fixedWidth={true} /> Grammatical Mistake
              </a>

              <a 
              className={'feedback-type-button'} 
              onClick={() => {this.startFeedback('documentation_request')}}>
                <FontAwesomeIcon className={'mr-3'} icon={faBooks} fixedWidth={true} /> Documentation Request
              </a>

              <a 
              className={'feedback-type-button'} 
              onClick={() => {this.startFeedback('feedback')}}>
                <FontAwesomeIcon className={'mr-3'} icon={faMemo} fixedWidth={true} /> General Feedback
              </a>

              <a 
              className={'feedback-type-button'} 
              onClick={() => {this.startFeedback('bug_report')}}>
                <FontAwesomeIcon className={'mr-3'} icon={faBug} fixedWidth={true} /> Bug Report
              </a>
            </div>
          </div>
          }
        </Modal>

      {(() => {
        const params = new URLSearchParams(this.props.location.search);
        if (params.get('search')) {
          return (
            <SearchInterface {...this.props} />
          )
        }

        if (this.props.path && this.props.path.indexOf('/docs') === 0) {
          return (
              <DocumentationTemplate {...this.props} />
            )
        }
        
        if (this.props.location.hostname === 'help-fin.dealr.cloud') {
          return (
              <IntercomFin  {...this.props} />
            )
        }

        return (
          <div>
            {this.props.children}
          </div>
        )
      })()}
    </div>)
    
  }
}

export default connect(
  (state, props) => {
    return {
      Main: state.Main,
    };
  },
  {
    setSettings,
    setPermissions,
    setServices,
    setAccountingMapping,
    setState,
  }
)(indexLayout);
