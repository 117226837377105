import * as React from "react";
import LogoFooter from "../../assets/images/logo-light.inline.svg";
import PropTypes from "prop-types";

const Footer = ({ fullWidth }) => {
  return (
    <footer className={'main-footer d-print-none'}>
      <div className={'container'}>
        <div className={'d-flex justify-content-center'}>
          <div className={'text-center'}>
            <div>
              © {new Date().getFullYear()} Dealr, Inc.
            </div>
            <div className={'mt-3'}>
              <a href={'https://dealr.cloud'} target={'_blank'}><img src={LogoFooter} height={'50px'} /></a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  fullWidth: PropTypes.bool
}

Footer.defaultProps = {
  fullWidth: false,
}

export default Footer
