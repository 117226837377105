import {connect} from "react-redux";
import * as React from "react";

const AdminBadge = (props) => {
  if (props.Main.permissions.super_admin) {
    return (<div className={'badge rounded-pill bg-danger '+props.className}>Admin</div>);
  }
}

export default connect(
  (state, props) => {
    return {
      Main: state.Main,
    };
  }
)(AdminBadge);
