import { SET_SETTINGS, SET_PERMISSIONS, SET_SERVICES } from '../actionTypes';

const initialState = {
  settings: null,
  permissions: null,
  accountingMapping: null,
};

export const setSettings = (settings) => {
  return {type: SET_SETTINGS, payload: { settings }};
};
export const setPermissions = (permissions) => {
  return {type: SET_PERMISSIONS, payload: { permissions }};
};
export const setServices = (services) => {
  return {type: SET_SERVICES, payload: { services }};
};
export const setAccountingMapping = (accountingMapping) => {
  return {type: 'SET_ACCOUNTING_MAPPING', payload: { accountingMapping }};
};
export const setState = (data) => {
  return {type: 'SET_STATE', payload: data };
};


export function mainReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SETTINGS:
      const { settings } = action.payload;
      return {
        ...state,
        settings: settings,
      };
    case SET_PERMISSIONS:
      const { permissions } = action.payload;
      return {
        ...state,
        permissions: permissions,
      };
    case SET_SERVICES:
      const { services } = action.payload;
      return {
        ...state,
        services: services,
      };
    case 'SET_ACCOUNTING_MAPPING':
      const { accountingMapping } = action.payload;
      return {
        ...state,
        accountingMapping: accountingMapping,
      };
    case 'SET_STATE':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}