import {useState, useEffect, default as React} from "react";

import algoliasearch from "algoliasearch/lite"
import aa from 'search-insights';

import { faSearch, faExternalLink } from '@fortawesome/pro-regular-svg-icons'
import { faFileLines} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/pro-light-svg-icons'
import { AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {navigate} from "gatsby";
import LoadingPuff from '/assets/images/loading-puff.inline.svg'

import { useHotkeys } from 'react-hotkeys-hook';
import {connect} from "react-redux";

import hasPermission from "../util/hasPermission";

const SearchBarOption = (option, props) => {
  return (
    <div className={`docs-searchbar__item ${props.isFocused ? 'docs-searchbar__item--focused' : ''}`}>
      {(() => {
        switch(option.index) {
          default:
          case process.env.GATSBY_ALGOLIA_SEARCH_DOCUMENTATION_INDEX:
            return (
              <div>
                <div className={'d-flex'}>
                  <div className={'docs-searchbar__item__title flex-fill'} dangerouslySetInnerHTML={{__html: option._highlightResult.title.value}}/>
                  <span className={"text-primary"} onClick={(event) => {window.open(option.path, '_blank');event.stopPropagation()}} aria-label={`Open in a new tab.`}><FontAwesomeIcon icon={faExternalLink} /></span>
                </div>
                <div className={'docs-searchbar__item__path'}>
                  {option.parentPaths.map(path => path.label).join(" > ")}
                </div>
                <div className={'docs-searchbar__item__text'} dangerouslySetInnerHTML={{__html: option._snippetResult.text.value}} />
              </div>
            );
          case process.env.GATSBY_ALGOLIA_SEARCH_ARTICLES:
            return (
              <div>
                <div className={'d-flex'}>
                {option.internal == 1 ? (<div className={'mr-2 badge rounded-pill bg-danger'}>Internal</div>) : ''}
                  <div className={'docs-searchbar__item__title flex-fill'} dangerouslySetInnerHTML={{__html: option._highlightResult.title.value}}/>
                  <span className={"text-primary"} onClick={(event) => {window.open(option.path, '_blank');event.stopPropagation()}} aria-label={`Open in a new tab.`}><FontAwesomeIcon icon={faExternalLink} /></span>
                </div>
                <div className={'docs-searchbar__item__text'} dangerouslySetInnerHTML={{__html: option._snippetResult.text.value}} />
              </div>
            );
          case process.env.GATSBY_ALGOLIA_SEARCH_FORMS_INDEX:
            return (
              <div className="d-flex align-items-center">
                <FontAwesomeIcon icon={faFileLines} className={'mr-4 fa-2x'} />
                <div>
                  <div className={'d-flex align-items-center'}>
                    <FontAwesomeIcon icon={faExternalLink} className={'mr-2'} />
                    <div className={'docs-searchbar__item__title flex-fill'} dangerouslySetInnerHTML={{__html: option._highlightResult.name.value}}/>
                    {/*<a target={'_blank'} href={option.path} onClick={(event) => {console.log(event);event.stopPropagation()}}><FontAwesomeIcon icon={faExternalLink} /></a>*/}
                  </div>
                  <div className={'docs-searchbar__item__path badge text-bg-primary'}  dangerouslySetInnerHTML={{__html: option._highlightResult?.state?.value}}></div>
                  <div className={'docs-searchbar__item__text'} dangerouslySetInnerHTML={{__html: option._highlightResult.description.value}} />
                  {option._snippetResult.instructions.matchLevel !== 'none' ? (<div className={'docs-searchbar__item__text'} dangerouslySetInnerHTML={{__html: option._snippetResult.instructions.value}} />) : null}
                </div>
              </div>
            );
            break;
          case process.env.GATSBY_ALGOLIA_SEARCH_PRICING_STRUCTURE_INDEX:
            return null;
        }
      })()}
    </div>
  );
}

const SearchbarMenu = (props) => {
  return (results, menuProps) => {

    if (!!props.searchPage) {
      return (
        <Menu {...{
          ...menuProps, 
          style: {}, // Override style for the typeahead page.
        }}>
          {results.map((result, index) => (
              <MenuItem option={result} position={index} key={index}>
                {SearchBarOption(result, menuProps)}
              </MenuItem>
          ))}
        </Menu>
      );
    }

    return (
      <div>
        <Menu {...menuProps}>
          {results.map((result, index) => (
              <MenuItem option={result} position={index} key={index}>
                {SearchBarOption(result, menuProps)}
              </MenuItem>
          ))}
        </Menu>
      </div>
    );
  };
};

const SearchbarInput = (props) => {
  const [focused, setFocus] = useState(false);

  return ({ inputRef, referenceElementRef, ...inputProps }) => {
    return (
      <div className="d-flex align-items-center">
        <div className={`flex-fill input-group docs-searchbar ${focused ? 'docs-searchbar--focused' : ''} ${props.className || ''} ${!!props.searchPage ? 'docs-searchbar--search-page' : ''} ${props.closeSearchPanel ? 'mr-2' : ''}`}>
          <span className={'input-group-text'}>
            <FontAwesomeIcon icon={faSearch} />
          </span>
          <input
            {...inputProps}
            className={`${inputProps.className || ''} rbt-input-main form-control rbt-input`}
            onFocus={() => {
              setFocus(true);
              //setTimeout(() => {setRerender(rerender+1)}, 250);
            }}
            onBlur={() => {
              setFocus(false);
              //setTimeout(() => {setRerender(rerender+1)}, 250);
            }}
            ref={(input) => {
              // Be sure to correctly handle these refs. In many cases, both can simply receive
              // the underlying input node, but `referenceElementRef can receive a wrapper node if
              // your custom input is more complex (See TypeaheadInputMulti for an example).
              inputRef(input);
              referenceElementRef(input);
            }}
          />
          <span className={'input-group-text docs-searchbar__shortcut-addon'}>
            {typeof navigator !== 'undefined' ? (navigator?.platform.toUpperCase().indexOf('MAC')>=0 ? "⌘ + K" : "ctrl + K") : ''}
          </span>
        </div>
        {props.closeSearchPanel ? (<a className={`docs-search-page-panel__close-icon onClick mr-2`} aria-label="Close search panel." onClick={props.closeSearchPanel}><FontAwesomeIcon icon={faXmark} size="lg" /></a>) : ''}
      </div>
    );
  }
}

const SearchBar = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [focused, setFocus] = useState(false);
  const [rerender, setRerender] = useState(0);
  const [selectRef, setSelectRef] = useState(React.createRef());
  const [searchValue, setSearchValue] = useState('');
  const [navigationLoading, setNavigationLoading] = useState(false);
  const [queryId, setQueryId] = useState(null);
  const [lastAlgoliaSearch, setLastAlgoliaSearch] = useState(null);

  const permissionsFlattened = [];

  if (props.Main.permissions) {
    for (const [key1, value1] of Object.entries(props.Main.permissions)) {
      if (typeof value1 === 'object') {
        for (const [key2, value2] of Object.entries(value1)) {
          if(value2) permissionsFlattened.push(`${key1}.${key2}`);
        }
      } else {
        if(value1) permissionsFlattened.push(key1);
      }
    }
  }
  permissionsFlattened.push('visible_to_all');



  const algoliaClient = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_SEARCH_KEY);
  const index = algoliaClient.initIndex(process.env.GATSBY_ALGOLIA_SEARCH_DOCUMENTATION_INDEX);

  aa('init', {
    appId: process.env.GATSBY_ALGOLIA_APP_ID,
    apiKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY
  });

  const handleSearch = async (query) => {
    if (typeof window === 'object') {
      const url = new URL(window.location.href);
      url.searchParams.set('search', query);
      window.history.replaceState({}, document.title, url.href);
    }
    
    if (typeof props.setCurrentSearch === 'function') {
      props.setCurrentSearch(query);
    }

    setIsLoading(true);
    setSearchValue(query);
    let permissionFilters = [];
    if (permissionsFlattened.indexOf('super_admin') === -1) {
      permissionFilters.push(...permissionsFlattened.map(p => `permissions:${p}`));
    }
    permissionFilters = permissionFilters.length ? `(${permissionFilters.join(' OR ')})` : '';

    let date = Math.floor((new Date()).getTime()/1000);

    const queries = [
      {
        indexName: process.env.GATSBY_ALGOLIA_SEARCH_DOCUMENTATION_INDEX,
        query: query,
        params: {
          clickAnalytics: true,
          filters: `${permissionFilters ? `${permissionFilters}` : ''}`,
          hitsPerPage: 10,
        }
      },
      {
        indexName: process.env.GATSBY_ALGOLIA_SEARCH_ARTICLES,
        query: query,
        params: {
          clickAnalytics: true,
          filters: `${permissionsFlattened.indexOf('super_admin') === -1 ? 'internal:0 AND ' : ''}hide_from_search:0 AND (available_date_timestamp = 0 OR available_date_timestamp <= ${date} ) AND (unavailable_date_timestamp = 0 OR unavailable_date_timestamp >= ${date} )`,
          hitsPerPage: 10,
        }
      },
    ];

    if (props.Main.permissions.super_admin) {
      queries.push(
        {
          indexName: process.env.GATSBY_ALGOLIA_SEARCH_FORMS_INDEX,
          query: query,
          params: {
            clickAnalytics: true,
            filters: `state_${props.Main.settings.state}<score=3> OR state_none<score=2> OR NOT _tags:state_${props.Main.settings.state}<score=1>`,
            hitsPerPage: 10,
          }
        },
        /* TODO: Future development for supporting the pricing structure. {
          indexName: process.env.GATSBY_ALGOLIA_SEARCH_PRICING_STRUCTURE_INDEX,
          query: query,
          params: {
            clickAnalytics: true,
            filters: `state_${props.Main.settings.state}<score=3> OR state_none<score=2> OR NOT _tags:state_${props.Main.settings.state}<score=1>`,
            hitsPerPage: 10,
          }
        },*/
      );
    }

    const result = await algoliaClient.multipleQueries(queries, {

    });
    setLastAlgoliaSearch(result);

    const hits = [];
    result.results.forEach((result) => {
      hits.push(...result.hits.filter((hit) => {
        switch(result.index) {
          case process.env.GATSBY_ALGOLIA_SEARCH_DOCUMENTATION_INDEX:
            let permission = true;
            hit.permissions.forEach((permission) => {
              if (permission !== 'visible_to_all' && !hasPermission(props.Main.permissions, permission)) {
                permission = false;
              }
            });
            return permission;
        }
        return true;
      }).map((hit) => {
        switch(result.index) {
          case process.env.GATSBY_ALGOLIA_SEARCH_DOCUMENTATION_INDEX:
            break;
          case process.env.GATSBY_ALGOLIA_SEARCH_ARTICLES:
            //hit.title = hit.title;
            break;
          case process.env.GATSBY_ALGOLIA_SEARCH_FORMS_INDEX:
            hit.title = hit.name;
            break;
          case process.env.GATSBY_ALGOLIA_SEARCH_PRICING_STRUCTURE_INDEX:
            hit.title = hit.name;
            break;
        }
        return {
          ...hit,
          index: result.index,
        };
      }));
    });

    setOptions(hits);
    setIsLoading(false);
  };

  useHotkeys('ctrl+k, command+k', () => {
    selectRef.current.focus();
  });

  const onSelection = async (item) => {
    selectRef.current.clear();
    aa('clickedObjectIDsAfterSearch', {
      userToken: String(props.Main.settings.user.id),
      index: item[0].index,
      eventName: 'clickedSearchObject',
      queryID: lastAlgoliaSearch.results.filter((result) => {return result.index === item[0].index})[0].queryID,
      objectIDs: [item[0].objectID],
      positions: [options.indexOf(item[0])+1]
    });

    let path = item[0].path;
    let hash = '';

    if (path.indexOf('#') > -1) {
      hash = path.slice(path.indexOf('#'));
      path = path.slice(0, path.indexOf('#'))
    }

    switch(item[0].index) {
      case process.env.GATSBY_ALGOLIA_SEARCH_DOCUMENTATION_INDEX:
        if (path.slice(path.length - 1) !== '/') {
          path = path + '/';
        }

        path = `${path}${searchValue ? `?search=${searchValue}` : ''}${hash}`;
        setNavigationLoading(true);

        await navigate(
          path
        );
        break;
      case process.env.GATSBY_ALGOLIA_SEARCH_ARTICLES:
        path = `${path}${searchValue ? `?search=${searchValue}` : ''}${hash}`;
        setNavigationLoading(true);

        await navigate(
          path
        )
        break;
      case process.env.GATSBY_ALGOLIA_SEARCH_FORMS_INDEX:
        window.open(`${process.env.GATSBY_API_URL}/admin/app/manage/forms/${item[0].id}`, "_blank");
        break;
      case process.env.GATSBY_ALGOLIA_SEARCH_PRICING_STRUCTURE_INDEX:
        break;
    }

    setNavigationLoading(false);

    selectRef.current.state.text = searchValue;

    if (typeof window !== 'undefined' && window.location.hash) {
      const item = document.querySelector(`${window.location.hash}`);
      if (item) {
        const footer = document.querySelector(`.main-footer`);

        let distance = item.offsetTop;
        if (footer.offsetTop - item.offsetTop < window.innerHeight) {
          distance = footer.offsetTop - window.innerHeight;
        }

        window.scrollTo({
          top: distance,
          behavior: "instant",
        });

        setTimeout(() => {
          if (window.scrollY != distance) {
            window.scrollTo({
              top: distance,
              behavior: "smooth",
            })
          }
        }, 100)
        item.classList.add("highlight-anchor-tag");

        setTimeout(() => {
          item.classList.remove("highlight-anchor-tag");
        }, 2000)
      }
    }
  }

  useEffect(() => {
    if (props.currentSearch) {
      setSearchValue(props.currentSearch);
      handleSearch(props.currentSearch);
    }
  }, []);

  return (
    <div className="h-100">
      <AsyncTypeahead
        id={'search-documentation'}
        isLoading={isLoading}
        minLength={3}
        labelKey={'title'}
        onSearch={handleSearch}
        options={options}
        placeholder="Search documentation..."
        defaultInputValue={props.currentSearch || ''}
        open={!!props.searchPage ? true : undefined}
        onFocus={() => {
          setFocus(true);
          setTimeout(() => {setRerender(rerender+1)}, 250);
        }}
        onBlur={() => {
          setFocus(false);
          setTimeout(() => {setRerender(rerender+1)}, 250);
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter' && !props.searchPage) {
            navigate(
              "/search-page/?search=" + searchValue
            )
            selectRef.current.state.text = '';
          }
        }}
        filterBy={() => true}
        useCache={false}
        ref={selectRef}
        onChange={onSelection}

        renderInput={SearchbarInput(props)}

        renderMenu={SearchbarMenu(props)}
        // renderMenuItemChildren={SearchBarOption}
        className={`docs-searchbar-container  ${!!props.searchPage ? 'docs-searchbar-container--search-page' : ''}`}
      />
    {navigationLoading ? (<div className="position-absolute d-flex w-100 h-100 top-0 justify-content-center align-items-center" style={{backgroundColor: 'rgba(255,255,255,.7)'}}>
        <img src={LoadingPuff} height={'50'} width={'50'} alt='Loading icon.' />
      </div>) : false}
    </div>
  );
};

export default connect(
  (state, props) => {
    return {
      Main: state.Main,
    };
  }
)(SearchBar);
