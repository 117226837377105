/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Footer from "./footer";
import Seo from "./seo";

const Layout = ({ children }) => {
  return (
    <div className={'d-flex flex-column'} style={{height: 100 + 'vh'}}>
      <Header siteTitle={`Help Center | dealr.cloud`} />
      <Seo />
      <div className={'container flex-fill'}>
        <main>{children}</main>
      </div>
      <Footer fullWidth={false} />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
