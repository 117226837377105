/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import "bootstrap/dist/js/bootstrap.min.js";
import "@popperjs/core/dist/umd/popper.min.js";
import "/src/scss/app.scss"
import "katex/dist/katex.min.css"
// You can delete this file if you're not using it
import wrapWithProvider from "./wrap-with-provider"

import ReactDOM from 'react-dom/client';

export const wrapRootElement = wrapWithProvider

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}

export const shouldUpdateScroll = ({
    prevRouterProps,
    routerProps: { location },
  }) => {
  if (prevRouterProps && prevRouterProps.location && prevRouterProps.location.pathname === location.pathname) {
    return false;
  }

  if (location.pathname.indexOf('/docs/') === 0) {
    const item = document.querySelector('.docs-main');
    if (item && item.getBoundingClientRect().top < 0 && !location.hash) {
      window.scrollTo({
        top: item.offsetTop,
      });
      return false;
    }
  }

  return true;
}