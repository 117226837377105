import _ from "lodash";

const hasPermission = function(permissions, permission) {
  if (typeof permission === 'undefined') permission = '';
  if (permissions === null) return false;
  if (permission.indexOf(".no_") > -1) {
    if (permissions.super_admin) {
      return false;
    } else {
      return !!_.get(permissions, permission, false);
    }
  } else {
    if (permissions.super_admin) {
      return true;
    } else {
      return !!_.get(permissions, permission, false);
    }
  }
}

export default hasPermission;