import * as React from "react";
import { useState } from "react";

import {useStaticQuery, graphql} from "gatsby"
import Header from "../components/header";
import Footer from "../components/footer";
import Sidebar from "../components/documentation/sidebar";
import _ from "lodash";
import { faStream } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'react-medium-image-zoom/dist/styles.css'
import LoadingPuff from '/assets/images/loading-puff.inline.svg'

import { connect } from 'react-redux';

export const flattenSubmenuPages = (items) => {
  return Object.values(items).map((item) => {
    if (!item.submenu) return item;

    const pages = flattenSubmenuPages(item.pages || {});
    return {
      ...item,
      pages: pages,
      pageIds: pages.reduce((accumulator, page) => [...accumulator, ...(page.submenu ? page.pageIds : [page.id])], []),
    };
  });
}

export const orderSubmenuPages = (items) => {
  items = items.map((item) => {
    if (item.submenu) {
      item.pages = orderSubmenuPages(item.pages);
    }
    return item;
  })

  let positionItems = items.filter(item => typeof item.position !== 'undefined' && item.position !== null);
  let noPositionItems = items.filter(item => typeof item.position === 'undefined' || item.position === null);
  positionItems = _.sortBy(positionItems, (item) => item.position);
  noPositionItems = _.sortBy(noPositionItems, (item) => item.folderPath);

  return [...positionItems, ...noPositionItems];
}

const DocumentationTemplate = function(props) {

  const data = useStaticQuery(graphql`
    query DocQuery {
      site {
        siteMetadata {
          title
        }
      }
      documentationPages: allSitePage {
        edges {
          node {
            path
            context: pageContext
          }
        }
      }
    }
  `)

  const [showMobileSidebar, setShowMobileSidebar] = useState(false);

  let sidebarItems = {};
  if (data.documentationPages) {
    for (const page of data.documentationPages.edges) {
      if (page.node.context.type !== 'documentation') continue;
      let lodashPath = '';
      if (page.node.context?.parentPaths?.length) {
        for (const path of page.node.context.parentPaths) {
          if (lodashPath) lodashPath += '.';
          lodashPath += path.folderPath;
          if (!_.has(sidebarItems, `${lodashPath}`)) {
            _.set(sidebarItems, `${lodashPath}`, {
              label: path.label,
              slug: path.slug,
              position: path.position,
              submenu: true,
              permission: path.permission,
              hidden: path.hidden,
              section_separator: path.section_separator,
              folderPath: path.folderPath,
            })
          }
          if (page.node.context.parentPaths.indexOf(path) === page.node.context.parentPaths.length - 1) {
            _.set(sidebarItems, `${lodashPath}.pages.${page.node.context.urlPath}`, page.node.context);
          }
          lodashPath += `.pages`
        }
      } else {
        _.set(sidebarItems, page.node.context.urlPath, page.node.context);
      }
    }
  }

  sidebarItems = orderSubmenuPages(flattenSubmenuPages(sidebarItems));

  let content;
  if (!props.Main.settings || !props.Main.permissions) {
    content = (<div className={'flex-fill d-flex align-items-center justify-content-center'}><img src={LoadingPuff} height={'100'} width={'100'} /></div>);
  } else {
    content = (<div className={'docs-main'}>
      <div className={`docs-main__navigation ${showMobileSidebar ? 'docs-main__navigation--show' : ''}`}>
        <div className={'mobile-documentation-sidebar-nav'}>
          <div className={'d-flex justify-content-start'}>
            <a onClick={() => {setShowMobileSidebar(!showMobileSidebar)}}><FontAwesomeIcon icon={faStream} /></a>
          </div>
        </div>
        <Sidebar items={sidebarItems} pageId={_.get(props, 'data.mdx.id')} onClick={() => {setShowMobileSidebar(!showMobileSidebar)}}/>
      </div>
      {props.children}
    </div>);
  }

  return (
    <div className={'d-flex flex-column '} style={{minHeight: 100 + 'vh'}}>
      <Header siteTitle={_.get(props, 'data.site.siteMetadata.title', `Title`)} fullWidth={true}/>
      <div className={'mobile-documentation-sidebar-nav'}>
        <div className={'d-flex justify-content-start'}>
          <a onClick={() => {setShowMobileSidebar(!showMobileSidebar)}}><FontAwesomeIcon icon={faStream} /></a>
        </div>
      </div>
      <div className={'flex-fill d-flex flex-column container-fluid'}>
        <main className={'flex-fill d-flex flex-column'}>
          {content}
        </main>
      </div>
      <Footer fullWidth={true} />
    </div>
  );
}

const DocumentationTemplateConnect = connect(
  (state, props) => {
    return {
      Main: state.Main,
    };
  }
)(DocumentationTemplate);

export default DocumentationTemplateConnect;