import {default as React, useEffect} from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import AnimateHeight from 'react-animate-height';

import {faChevronRight, faLink} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import hasPermission from "../../util/hasPermission";
import {connect} from "react-redux";
import AdminBadge from "./admin_badge";
import $ from 'jquery';

import { globalHistory } from '@reach/router'

const isInViewport = function(element) {
  const rect = element.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

const PageChangeWatcher = () => {

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        setTimeout(() => {
          const element = $('.docs-navigation__item--active');
          if (element.length) {
            if (!isInViewport(element.get(0))) {
              const offset = element.offset(); // Contains .top and .left
              $('.docs-navigation').animate({
                scrollTop: offset.top,
              });
            }
          }
        }, 500);
      }
    });
  });
  return (<></>);
}

class Sidebar extends React.Component {
  constructor(props) {
    super();
    this.generateNavItemsHtml = this.generateNavItemsHtml.bind(this);

    this.props = props;
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.props.onClick(false);
  }

  processSidebarItems(items) {
    return items.map(item => {
      if (item.submenu) {
        return {
          ...item,
          opened: item.pageIds.indexOf(this.props.pageId) > -1,
          active: item.pageIds.indexOf(this.props.pageId) > -1,
          pages: this.processSidebarItems(item.pages),
        }
      } else {
        return {
          ...item,
          active: item.id === this.props.pageId,
        };
      }
    })
  }
  generateNavItemsHtml(items, parentPermission = '') {
    return items.map((item, index) => {
      let sectionSeparatorCode;
      if (item.section_separator) {
        sectionSeparatorCode = <div className={'docs-navigation__separator'} key={`${index}_separator`}>{item.section_separator}</div>;
      }
      if (item.submenu) {
        if ((item.permission && !hasPermission(this.props.Main.permissions, item.permission)) || (item.service && !this.props.Main.services[this.service])) return;
        if (item.hidden) return;
        const active = item.pageIds.indexOf( this.props.pageId) > -1;
        return (
          <React.Fragment key={index}>
            {sectionSeparatorCode}
            <SidebarSubmenu className={'docs-navigation__item docs-navigation__item-submenu ' + (active ? 'docs-navigation__item-submenu--active' : '')} key={index} keyIndex={index} item={item} pageId={this.props.pageId} Main={this.props.Main} active={active}>
              {this.generateNavItemsHtml(item.pages, item.permission)}
            </SidebarSubmenu>
          </React.Fragment>);
      } else {
        const active = this.props.pageId === item.id;
        if ((item.permission && !hasPermission(this.props.Main.permissions, item.permission)) || (item.service && !this.props.Main.services[item.service])) return;
        if (item.hidden) return;
        return (<React.Fragment key={index}>
          {sectionSeparatorCode}
          <div className={'docs-navigation__item ' + (active ? 'docs-navigation__item--active' : '')} key={index}><Link to={`/docs/${item.urlPath}`} onClick={this.onClick}>{item.redirect ? (<FontAwesomeIcon icon={faLink} className={'me-2 docs-navigation__item__redirect-indicator'} />) : ''}{item.label}{(item.permission === 'super_admin' || parentPermission === 'super_admin') && this.props.Main.permissions.super_admin ? (<AdminBadge className={'ms-2'} />) : ''}</Link></div>
        </React.Fragment>);
      }
    });
  }

  render() {
    return (
      <div className={'docs-navigation'}>
        <PageChangeWatcher />
        {this.generateNavItemsHtml(this.props.items)}
      </div>
    )
  }
}

class SidebarSubmenu extends React.Component {
  constructor(props) {
    super();
    this.toggleNavItemExpansion = this.toggleNavItemExpansion.bind(this);
    this.checkSubmenuOpenStatus = this.checkSubmenuOpenStatus.bind(this);

    this.state = {
      initialOpened: false,
      selected: props.item.pageIds.indexOf(props.pageId) > -1,
    };
  }

  toggleNavItemExpansion(event) {
    const opened = typeof this.state.opened !== 'undefined' ? !this.state.opened : !this.state.initialOpened;
    this.setState({
      opened,
    });

    if (opened) {
      const submenu = $(event.target).closest('.docs-navigation__item-submenu');

      setTimeout(function() {
        if (!isInViewport(submenu.get(0))) {
          const offset = submenu.offset(); // Contains .top and .left
          $('.docs-navigation').animate({
            scrollTop: offset.top,
          });

          const item = document.querySelector('.docs-main');
          if (item) {
            window.scrollTo({
              top: item.offsetTop,
              //behavior: "instant",
            });
          }
        }
      }, 300);
    }
  }

  checkSubmenuOpenStatus() {
    if (typeof this.state.opened === 'undefined' && this.props.active && !this.state.initialOpened) {
      this.setState({
        initialOpened: true,
      })
    }
    if (typeof this.state.opened !== 'undefined' && !this.props.active && !this.state.opened) {
      this.setState({
        initialOpened: false,
        opened: undefined,
      })
    }
  }
  componentDidMount() {
    this.checkSubmenuOpenStatus();
  }

  // This code gets called for every submenu that gets rendered and for each time a change happens in the sidebar.
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.pageId && this.props.pageId && this.props.pageId != prevProps.pageId) {
      this.checkSubmenuOpenStatus();
    }
  }
  render() {
    const opened = typeof this.state.opened === 'undefined' ? this.state.initialOpened : this.state.opened;

    return (
      <>
        <div key={this.props.keyIndex} className={this.props.className + (opened ? ' docs-navigation__item-submenu--opened' : '')}>
          <a className={'d-flex'} onClick={this.toggleNavItemExpansion}>
            <span className={'flex-fill'}>
              {this.props.item.label}{(this.props.item.permission === 'super_admin') && this.props.Main.permissions.super_admin ? (<AdminBadge className={'ms-2'} />) : ''}
            </span>
            <span className={'navigation-chevron'}>
              <FontAwesomeIcon icon={faChevronRight} /*transform={'shrink-5'}*/ />
            </span>
          </a>
          <AnimateHeight className={'docs-navigation__item-submenu__items'} duration={300} height={opened ? 'auto' : 0}>
            {this.props.children}
          </AnimateHeight>
        </div>
    </>
    )
  }
}

Sidebar.propTypes = {
  items: PropTypes.array,
  pageId: PropTypes.string,
  mappedFiles: PropTypes.array,
}

Sidebar.defaultProps = {
  items: [],
  pageId: ``
}

SidebarSubmenu.propType = {
  item: PropTypes.object,
  pageId: PropTypes.string,
}

export default connect(
  (state, props) => {
    return {
      Main: state.Main,
    };
  }
)(Sidebar);