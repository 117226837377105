import * as React from "react"
import _ from "lodash";

import { flattenSubmenuPages, orderSubmenuPages } from "./documentationTemplate";
import {graphql} from "gatsby"

import Layout from "../components/layout"
import { useStaticQuery, Link } from "gatsby"

const generateListStructure = function(item) {
  if (item.permission === 'super_admin') return false
  if (item.pages) {
      return (
          <li key={item.id || item.folderPath}>
              {item.label}
              <ul>
                  {item.pages?.map(page => generateListStructure(page))}
              </ul>
          </li>
      );
  } else {
      return (
          <li key={item.id || item.folderPath}>
              <Link to={`/docs/${item.urlPath}`} >{item.label}</Link>
          </li>
      );
  }
}


export default function IntercomFin() {  
  return '';
  // // const data = useStaticQuery(graphql`
  // //   query FinListQuery {
  // //     documentationPages: allSitePage(filter: {context:{type: {eq: "documentation"}}}) {
  // //       edges {
  // //         node {
  // //           path
  // //           context: pageContext
  // //         }
  // //       }
  // //     }
  // //   }
  // // `);

  // let sidebarItems = {};

  //   if (data.documentationPages) {
  //     for (const page of data.documentationPages.edges) {
  //       let lodashPath = '';
  //       for (const path of page.node.context.parentPaths) {
  //         if (lodashPath) lodashPath += '.';
  //         lodashPath += path.folderPath;
  //         if (!_.has(sidebarItems, `${lodashPath}`)) {
  //           _.set(sidebarItems, `${lodashPath}`, {
  //             label: path.label,
  //             slug: path.slug,
  //             position: path.position,
  //             submenu: true,
  //             permission: path.permission,
  //             hidden: path.hidden,
  //             section_separator: path.section_separator,
  //             folderPath: path.folderPath,
  //           })
  //         }
  //         if (page.node.context.parentPaths.indexOf(path) === page.node.context.parentPaths.length - 1) {
  //           _.set(sidebarItems, `${lodashPath}.pages.${page.node.context.urlPath}`, page.node.context);
  //         }
  //         lodashPath += `.pages`
  //       }
  //       if (page.node.context.parentPaths.length === 0) {
  //         _.set(sidebarItems, page.node.context.urlPath, page.node.context);
  //       }
  //     }
  //   }

  //   sidebarItems = orderSubmenuPages(flattenSubmenuPages(sidebarItems));

  //   return (
  //     <Layout>
  //       <div className={'d-flex flex-column '} style={{minHeight: '100vh'}}>
  //           <ul>
  //           {sidebarItems.map(item => {
  //               return generateListStructure(item);
  //           })}
  //           </ul>
  //       </div>
  //     </Layout>
  //   )
}