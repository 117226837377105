import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Logo from "/assets/images/logo.inline.svg";
import SearchBar from "./searchbar";

const Header = ({ siteTitle, fullWidth }) => {
  return (
    <nav className="main-navbar navbar navbar-expand-lg navbar-dark d-print-none">
      <div className={fullWidth ? 'container-fluid' : 'container'}>
        <Link to={'/'} className="navbar-brand">
          <img src={Logo} height={'40px'} className={'ms-3'}/>
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0 me-5">
            <li className="nav-item">
              <Link to={'/docs/introduction'} className={'nav-link'}>Documentation</Link>
            </li>
          </ul>
          <SearchBar/>
        </div>
      </div>
    </nav>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  fullWidth: PropTypes.bool
}

Header.defaultProps = {
  siteTitle: ``,
  fullWidth: false,
}

export default Header
